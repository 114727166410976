import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useApiListing } from '@/composables/api/listing';
import type { Attribute } from '@/composables/attributesRenderer';
import { useFeatureConfig } from '@/composables/featureConfig';
import useGTM from '@/composables/useGTM';
import configApp from '@/config/app.json';
import { useStepsLpInterest } from '@/factories/formStepsFactory/lpInterest';
import translationsLP from '@/i18n/translations/components/listingPage.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import type { TCan } from '@/types';

export const useVarPool = () => {
  const { locale, t } = useI18n({ ...translationsLP, useScope: 'global' });

  const { indexDocuments } = useApiListing();
  const { user } = storeToRefs(useAuthStore());
  const listingStore = useListingStore();
  const { isPreview, listing, listingConfig, listingData, listingMetaData, newlyListedVisible } =
    storeToRefs(listingStore);
  const { featureConfig } = useFeatureConfig();

  const icons: Record<string, string> = {};

  const cans = computed(() => {
    if (!listing.value || !listingConfig.value || !listingData.value || !listingMetaData.value) {
      return [];
    }

    const isAcquired = listingData.value.listingInfo.statuses.acquired;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _cans: TCan[] = [];

    _cans.push(
      listing.value.commercializationType,
      listing.value.category,
      listing.value.type
      // ...listing.value.listingInfo.labels
    );

    if (
      !!listing.value.extraInformation.sections.length ||
      !!listing.value.extraInformation.units?.length
    ) {
      _cans.push('extra-info');
    }

    if (
      featureConfig.value.listing.listingDocuments &&
      (listingMetaData.value.hasDocuments || isPreview.value)
    ) {
      _cans.push('documents');
    }

    if (listingConfig.value.locationInfo?.enabled ?? true) {
      _cans.push('location');
    }

    if (listingData.value.propertyInfo.units.length > 1) {
      _cans.push('multi-unit');
    }

    if (listingMetaData.value.priceUponRequest) {
      _cans.push('price-upon-request');
    }

    if (
      featureConfig.value.listing.similarListings &&
      (listingConfig.value.similarListings?.enabled ?? true)
    ) {
      _cans.push('similar');
    }

    if (listing.value.availabilityStatus !== 'acquired') {
      _cans.push(configApp.agentInformationVisible ? 'agent' : 'help');
    }

    if (isAcquired) {
      _cans.push('acquired');
      return _cans;
    }

    // when listing is not acquired...
    if (newlyListedVisible.value) {
      _cans.push('new');
    }

    if (featureConfig.value.listing.downloadListingAsPdf) {
      _cans.push('download-pdf');
    }

    if (featureConfig.value.listing.loanRequest) {
      _cans.push('lp-mortgage');
    }

    if (featureConfig.value.listing.infoRequest) {
      _cans.push('lp-interest');
    }

    return _cans;
  });

  const badges = computed<Attribute[]>(() => [
    {
      key: 'availability',
      value: listing.value?.commercializationType
        ? t(`section.badges.${listing.value.commercializationType}`)
        : '',
    },
    {
      key: 'new',
      value: t('section.badges.newlyListed'),
      visible: cans.value.includes('new'),
    },
    {
      key: 'hot',
      value: t('section.badges.hot'),
      visible: listingStore.hasLabel('hot') && !cans.value.includes('acquired'),
    },
    {
      key: 'comingSoon',
      value: t('section.badges.comingSoon'),
      visible: listingStore.hasLabel('comingSoon'),
    },
  ]);

  const { factoryId: factoryIdLpInterest, getFormStepsLpInterest } = useStepsLpInterest();

  const lpInterestSteps = getFormStepsLpInterest();

  const formWidgets = computed(() => [
    {
      props: { factoryId: factoryIdLpInterest, flat: true, steps: lpInterestSteps.value },
      label: t('section.formWidget.label.contact'),
      name: 'contact',
      teleportTarget: '.layout-main-footer__body',
      visible: cans.value.includes('lp-interest'),
    },
  ]);

  const bootPage = () => {
    listingStore.loadCss();

    watch(user, v => {
      if (!listing.value || !v || listingData.value?.mediaInfo.documents?.length) return;

      indexDocuments(listing.value.id).then(({ data }) => {
        if (!listingData.value) return;

        listingData.value.mediaInfo.documents = data;
      });
    });

    watch(
      () => cans.value.length,
      v => {
        if (!v) return;

        listingStore.hydrateLro();
      }
    );

    watch(listing, v => {
      if (!v) return;

      useGTM('visit_property_page_listing_id', {
        item_id: v.id,
        item_price: v.price,
        item_type: v.type,
        item_category: v.category,
        item_com_type: v.commercializationType,
      });
    });

    watch(
      locale,
      () => {
        listingStore.loadListing();
      },
      { immediate: true }
    );
  };

  return { badges, bootPage, cans, configApp, formWidgets, icons, t };
};
